import '@/js/dist/www.iichi.com/common';

import Vue from 'vue';
import { pinia } from '@/js/lib/store';
import { useItemStore } from '@/js/lib/store/item';
import { uniq } from '@/js/lib/helper/array';
import Slider from '@/js/components/common/ui/slider.vue';
import LikeButtonSmall from '@/js/components/www.iichi.com/organisms/likeButtonSmall.vue';

const itemStore = useItemStore(pinia);

document.addEventListener('DOMContentLoaded', () => {
  const itemIds = Array.from(document.querySelectorAll('like-button-small'), (el) => Number(el.getAttribute(':item-id')));
  itemStore.initLikedData(uniq(itemIds));

  new Vue({
    el: '#content',
    components: {
      Slider,
      LikeButtonSmall,
    },
  });

  document.querySelectorAll('.compact-pager').forEach((pagerEl: HTMLDivElement) => {
    const id = pagerEl.getAttribute('target');
    if (!id) {
      return;
    }

    const containerEl = document.querySelector(`#${id}`);
    if (!containerEl) {
      return;
    }

    const pageEls: NodeListOf<HTMLUListElement> = containerEl.querySelectorAll('.compact-pager-page');
    const count = pageEls.length;
    if (count <= 1) {
      // eslint-disable-next-line no-param-reassign
      pagerEl.style.display = 'none';
      return;
    }

    let currentIndex = 0;
    const updateTitle = (pageEl: HTMLUListElement) => {
      // eslint-disable-next-line no-param-reassign
      pagerEl.querySelector('.current').textContent = pageEl.title || currentIndex + 1 + '/' + count;
    };
    const showPage = (i: number) => {
      pageEls.forEach((el) => {
        el.style.display = 'none';
      });
      const pageEl = pageEls[i];
      pageEl.style.display = 'block';
      updateTitle(pageEl);
    };

    updateTitle(pageEls[0]);
    pagerEl.querySelector('.prev').addEventListener('click', () => {
      currentIndex = (count + currentIndex - 1) % count;
      showPage(currentIndex);
    });
    pagerEl.querySelector('.next').addEventListener('click', () => {
      currentIndex = (count + currentIndex + 1) % count;
      showPage(currentIndex);
    });
  });
});
